



























































import { Component, Prop, ProvideReactive, Vue } from "vue-property-decorator";
import {
  APICallChart,
  AreYouSureModal,
  OrganizationMenu,
  QuotaUsage,
  ValidatedTextInputModal,
  WalletCard,
} from "@/components";
import { Wallet } from "@/types";
import { Api } from "@/api";

@Component({
  components: {
    APICallChart,
    AreYouSureModal,
    OrganizationMenu,
    QuotaUsage,
    ValidatedTextInputModal,
    WalletCard,
  },
})
export default class Wallets extends Vue {
  // @Prop({ type: String, required: true }) id!: string;
  // private organizationName = "Loading...";
  // private addWalletModalVisible = false;
  // private wallets: Wallet[] = [];
  // private areYouSureTitle = "";
  // private areYouSureProgressText = "";
  // private areYouSureModalVisible = false;
  // /* eslint-disable @typescript-eslint/ban-types */
  // private areYouSureConfirmedCallback: Function | null = null;
  // private async created(): Promise<void> {
  //   await Promise.all([this.getOrganizationName(), this.getAllWallets()]);
  // }
  // private async getOrganizationName(): Promise<void> {
  //   const organization = await Api.getOrganizationData(this.id);
  //   this.organizationName = organization.name;
  // }
  // private async getAllWallets(): Promise<void> {
  //   this.wallets = await Api.getAllWallets(this.id);
  // }
  // @ProvideReactive("loading-did")
  // private loadingDid = false;
  // private async onDIDCreated(walletId: string): Promise<void> {
  //   this.loadingDid = true;
  //   const did = await Api.createDid(walletId);
  //   this.wallets = this.wallets.map((w) => {
  //     if (w.id === walletId) {
  //       w.dids.push(did);
  //     }
  //     return w;
  //   });
  //   this.loadingDid = false;
  // }
  // private confirmDeleteWallet(walletId: string): void {
  //   this.areYouSureTitle = "Are you sure you would like to delete this Wallet?";
  //   this.areYouSureProgressText = "Deleting wallet...";
  //   this.areYouSureModalVisible = true;
  //   this.areYouSureConfirmedCallback = async () => {
  //     await this.deleteWallet(walletId);
  //   };
  // }
  // private async deleteWallet(walletId: string): Promise<void> {
  //   await Api.deleteWallet(this.id, walletId);
  //   this.wallets = this.wallets.filter((w) => w.id != walletId);
  // }
  // private confirmDeleteDID(walletId: string, didToDelete: string): void {
  //   this.areYouSureTitle = "Are you sure you would like to tombstone this DID?";
  //   this.areYouSureProgressText = "Tombstoning DID...";
  //   this.areYouSureModalVisible = true;
  //   this.areYouSureConfirmedCallback = async () => {
  //     await this.deleteDID(walletId, didToDelete);
  //   };
  // }
  // private async deleteDID(
  //   walletId: string,
  //   didToDelete: string
  // ): Promise<void> {
  //   await Api.deleteDid(this.id, walletId, didToDelete);
  //   this.wallets = this.wallets.map((w) => {
  //     w.dids = w.dids.filter((did) => did.did != didToDelete);
  //     return w;
  //   });
  // }
  // private async areYouSureConfirmed(): Promise<void> {
  //   /* eslint-disable @typescript-eslint/no-non-null-assertion */
  //   await this.areYouSureConfirmedCallback!();
  //   this.areYouSureModalVisible = false;
  // }
  // private async onWalletRename(
  //   walletId: string,
  //   newName: string
  // ): Promise<void> {
  //   await Api.updateWalletName(this.id, walletId, { name: newName });
  //   this.wallets = this.wallets.map((w) => {
  //     if (w.id === walletId) {
  //       w.name = newName;
  //     }
  //     return w;
  //   });
  // }
  // private onAddWalletButtonClick(): void {
  //   this.addWalletModalVisible = true;
  // }
  // private async createNewWallet(newWalletName: string): Promise<void> {
  //   const wallet = await Api.createWallet(this.id, { name: newWalletName });
  //   this.wallets.push(wallet);
  //   this.addWalletModalVisible = false;
  // }
}
